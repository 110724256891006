var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-layout",
    { attrs: { view: "lHr lpR lFr" } },
    [_c("q-page-container", [_c("router-view")], 1)],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }