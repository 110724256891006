// Layout file

// All Pages conform to this same layout

<template>

<q-layout view="lHr lpR lFr">
    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
export default {
  name: "MyLayout",
  data() {
    return {};
  },
  methods: {
  }
};
</script>


<style>
.background {
    background-color: #fff;
}

.foundationFont {
  font-family: foundationFont;
}
</style>


